<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Formulario de edición de usuario {{ user.name }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.name"
                label="Nombre"
                :rules="
                  rules.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.email"
                label="Correo Electrónico"
                :rules="
                  emailRules.concat(
                    (v) => (v && v.length <= 200) || 'Máximo 200 caracteres'
                  )
                "
                :counter="200"
                hide-details="auto"
                :disabled="user.hasOwnProperty('id')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-switch v-model="user.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center" v-if="loading">
                <div class="spinner-border" role="status"></div>
              </div>
              <v-select
                v-if="!loading"
                v-model="user.roles"
                :items="roles"
                :item-text="'name'"
                :item-value="'name'"
                attach
                chips
                label="Seleccione uno o mas roles"
                multiple
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserService from "@/services/user.service"
import RoleService from "@/services/role.service"
import validationField from "@/util/validationField"
import { mapState } from "vuex"

export default {
  data() {
    return {
      user: {
        name: undefined,
        email: undefined,
        roles: [],
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      emailRules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
        (value) => validationField("email", value) || "Email inválido",
      ],
      roles: [],
      loading: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Gestión de Usuarios"))
      this.$router.push("/dashboard")

    if (localStorage.getItem("datos_usuario")) {
      this.user = JSON.parse(localStorage.getItem("datos_usuario"))
      this.user.roles = this.user.roles.map(({ permissions, ...restoObjeto }) => restoObjeto);
    }

    this.fetchRoles()
  },
  methods: {
    save() {
      this.loadingSave = true
      if (this.user.hasOwnProperty("id")) {
        UserService.editItem(this.user).then(
          (response) => {
            this.$router.push({ path: `/users` });
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        UserService.newItem(this.user).then(
          (response) => {
            this.$router.push({ path: `/users` })
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchRoles() {
      this.loading = true;
      RoleService.getItems().then(
        (response) => {
          this.roles = response.data
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/users" });
    },
  },
};
</script>

<style>
</style>
